import React from 'react';
import Slideshow from './Slideshow';

const Home = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    return (
        <div className="relative overflow-hidden">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative">
                    <div className="h-screen w-full">
                        <Slideshow />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home; 