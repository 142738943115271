import React, { useState } from 'react';
import PathNavigation from './PathNavigation';
import Footer from './Footer';

const Contact = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
    };

    return (
        <div className="relative">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen">
                    <div className="px-4 md:px-[30px] min-h-screen pt-[120px]">
                        {/* Contact Header */}
                        <div className="max-w-[450px]">
                            <h1 className="text-xs tracking-wider mb-[30px]">CONTACT</h1>
                            <p className="text-xs leading-[15px] tracking-wider">
                                Our support service will get back to you within 1-2 business days.
                            </p>
                        </div>

                        {/* Contact Form */}
                        <form onSubmit={handleSubmit} className="max-w-[450px] mt-[60px]">
                            {/* First Name */}
                            <div className="mb-[10px]">
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="*FIRST NAME"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                    className="w-full bg-transparent border-b border-[#999999] pb-[2px] text-xs tracking-wider focus:outline-none placeholder-[#999999]"
                                />
                            </div>

                            {/* Last Name */}
                            <div className="mb-[10px]">
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="*LAST NAME"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                    className="w-full bg-transparent border-b border-[#999999] pb-[2px] text-xs tracking-wider focus:outline-none placeholder-[#999999]"
                                />
                            </div>

                            {/* Subject */}
                            <div className="mb-[10px]">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="*SUBJECT"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                    className="w-full bg-transparent border-b border-[#999999] pb-[2px] text-xs tracking-wider focus:outline-none placeholder-[#999999]"
                                />
                            </div>

                            {/* Message */}
                            <div className="mb-0">
                                <label className="text-xs tracking-wider mb-[10px] block">
                                    *TYPE IN YOUR MESSAGE AND PRESS SEND
                                </label>
                                <div className="relative h-[150px]">
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        className="absolute bottom-0 w-full bg-transparent border-b border-[#999999] pb-[1px] text-xs tracking-wider focus:outline-none resize-none"
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200 mt-[10px]"
                            >
                                SEND MESSAGE
                            </button>
                        </form>
                    </div>

                    {/* Path Navigation */}
                    <PathNavigation />

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Contact; 