import React from 'react';

const Container = ({ children, className = '' }) => {
    return (
        <div className={`px-site ${className}`}>
            {children}
        </div>
    );
};

export default Container; 