import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import Container from './Container';
import Cart from './Cart';
import Search from './Search';

const Navbar = ({ children, isMobileMenuOpen, setIsMobileMenuOpen, darkMode = false, isCartOpen, setIsCartOpen, isSearchOpen, setIsSearchOpen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getCartCount } = useCart();

    const leftOptions = [
        { name: 'SHOP', path: '/shop' },
        { name: 'EXPLORE', path: '/explore' },
        { name: 'BRAND', path: '/brand' }
    ];
    const rightOptions = ['SEARCH', 'ACCOUNT', 'CART'];

    const textColorClass = darkMode ? "text-black" : "text-white";

    const handleNavigation = (path) => {
        navigate(path);
    };

    const getOptionOpacity = (path) => {
        return location.pathname === path ? 'opacity-50' : 'opacity-100';
    };

    const cartCount = getCartCount();

    const handleCartClick = () => {
        setIsCartOpen(!isCartOpen);
    };

    const handleSearchClick = () => {
        setIsSearchOpen(!isSearchOpen);
        if (isCartOpen) setIsCartOpen(false);
    };

    return (
        <>
            <div className="fixed top-0 left-0 right-0 z-50">
                {/* Desktop Navbar */}
                <div className="hidden md:block">
                    <Container className="py-[30px] relative flex justify-between items-center">
                        {/* Left Options */}
                        <div className="flex gap-16">
                            {leftOptions.map((option) => (
                                <button
                                    key={option.name}
                                    onClick={() => handleNavigation(option.path)}
                                    className={`${textColorClass} text-xs tracking-wider hover:opacity-50 transition-opacity duration-200 ${getOptionOpacity(option.path)}`}
                                >
                                    {option.name}
                                </button>
                            ))}
                        </div>

                        {/* Center Logo */}
                        <div
                            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                            onClick={() => handleNavigation('/')}
                        >
                            <img
                                src={darkMode ? "/assets/images/logo_black.svg" : "/assets/images/logo_white.svg"}
                                alt="SimSim Logo"
                                className="h-[42.9px]"
                            />
                        </div>

                        {/* Right Options */}
                        <div className="flex gap-16">
                            {rightOptions.map((option) => (
                                <button
                                    key={option}
                                    onClick={
                                        option === 'CART'
                                            ? handleCartClick
                                            : option === 'SEARCH'
                                                ? handleSearchClick
                                                : undefined
                                    }
                                    className={`${textColorClass} text-xs tracking-wider hover:opacity-50 transition-opacity duration-200`}
                                >
                                    {option}
                                    {option === 'CART' && cartCount > 0 && `(${cartCount})`}
                                </button>
                            ))}
                        </div>
                    </Container>
                </div>

                {/* Mobile Navbar */}
                <div className={`md:hidden transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                    <div className="px-[16px] pt-[20px] flex items-center justify-between">
                        {/* Menu Button */}
                        <button
                            onClick={() => setIsMobileMenuOpen(true)}
                            className={`${textColorClass} text-xs tracking-wider hover:opacity-50 transition-opacity duration-200`}
                        >
                            MENU
                        </button>

                        {/* Center Logo */}
                        <div
                            className="absolute left-1/2 top-[16px] -translate-x-1/2 cursor-pointer"
                            onClick={() => handleNavigation('/')}
                        >
                            <img
                                src={darkMode ? "/assets/images/logo_black.svg" : "/assets/images/logo_white.svg"}
                                alt="SimSim Logo"
                                className="w-[82px] h-[27px] object-contain"
                            />
                        </div>

                        {/* Cart Button */}
                        <button
                            onClick={handleCartClick}
                            className={`${textColorClass} text-xs tracking-wider hover:opacity-50 transition-opacity duration-200`}
                        >
                            CART{cartCount > 0 && `(${cartCount})`}
                        </button>
                    </div>
                </div>
            </div>
            {children}
        </>
    );
};

export default Navbar; 