import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import PathNavigation from './PathNavigation';
import Footer from './Footer';

const Shop = ({ products, isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const navigate = useNavigate();
    const [isExpandedView, setIsExpandedView] = useState(false);
    const [hoveredProductId, setHoveredProductId] = useState(null);

    const formatPrice = (amount, currencyCode) => {
        return `${Math.round(amount)} ${currencyCode}`;
    };

    const handleProductClick = (handle) => {
        navigate(`/product/${handle}`);
    };

    const CompactView = () => (
        <div className="grid grid-cols-2 md:grid-cols-3 cursor-pointer gap-x-0 gap-y-0">
            {products.map((product) => {
                const price = product.priceRange.minVariantPrice;
                const mainImage = product.images.edges[0]?.node;
                const hoverImage = product.images.edges[1]?.node || mainImage; // Fallback to main image if no second image

                return (
                    <div
                        key={product.id}
                        className="flex flex-col mb-5 md:mb-[30px]"
                        onClick={() => handleProductClick(product.handle)}
                        onMouseEnter={() => setHoveredProductId(product.id)}
                        onMouseLeave={() => setHoveredProductId(null)}
                    >
                        {/* Image Container */}
                        <div className="w-full aspect-[4/5] overflow-hidden">
                            <img
                                src={hoveredProductId === product.id ? hoverImage.url : mainImage.url}
                                alt={hoveredProductId === product.id ? hoverImage.altText : mainImage.altText || product.title}
                                className="w-full h-full object-cover transition-all duration-300 md:hover:opacity-75"
                            />
                        </div>

                        {/* Product Info */}
                        <div className="flex justify-between items-center px-4 md:px-[30px] mt-[10px]">
                            <span className="text-xs tracking-wider">
                                {product.title.toUpperCase()}
                            </span>
                            <span className="text-xs tracking-wider">
                                {formatPrice(price.amount, price.currencyCode)}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const ExpandedView = () => (
        <div className="flex flex-col gap-y-5 md:gap-y-[60px]">
            {products.map((product) => {
                const price = product.priceRange.minVariantPrice;
                const images = product.images.edges.slice(0, 3);

                return (
                    <div
                        key={product.id}
                        className="w-full cursor-pointer"
                        onClick={() => handleProductClick(product.handle)}
                    >
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-0">
                            {images
                                .slice(0, window.innerWidth >= 768 ? 3 : 2)
                                .map((image, index) => (
                                    <div
                                        key={index}
                                        className="aspect-[4/5] overflow-hidden"
                                    >
                                        <img
                                            src={image.node.url}
                                            alt={image.node.altText || product.title}
                                            className="w-full h-full object-cover transition-opacity duration-300 hover:opacity-50"
                                        />
                                    </div>
                                ))}
                        </div>
                        {/* Product Info */}
                        <div className="flex justify-between items-center px-4 md:px-[30px] mt-[10px]">
                            <span className="text-xs tracking-wider">
                                {product.title.toUpperCase()}
                            </span>
                            <span className="text-xs tracking-wider">
                                {formatPrice(price.amount, price.currencyCode)}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className="relative">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen">

                    <div className="pt-[140px]">
                        {/* View Toggle - adjusted padding for mobile */}
                        <div className="flex px-4 md:px-site mb-[20px]">
                            <button
                                className={`text-xs tracking-wider ${!isExpandedView ? 'opacity-100' : 'opacity-50'}`}
                                onClick={() => setIsExpandedView(false)}
                            >
                                LESS
                            </button>
                            <div className="w-[4px]" />
                            <button
                                className={`text-xs tracking-wider ${isExpandedView ? 'opacity-100' : 'opacity-50'}`}
                                onClick={() => setIsExpandedView(true)}
                            >
                                MORE
                            </button>
                        </div>

                        {/* Products Grid */}
                        {isExpandedView ? <ExpandedView /> : <CompactView />}

                        {/* Path Navigation */}
                        <PathNavigation />

                        {/* Footer */}
                        <Footer />
                    </div>
                </div>
            </div>
            <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
        </div>
    );
};

export default Shop; 