import React, { useEffect, useRef, useState } from "react";

const SlideProgress = ({ isActive, isFinished, duration = 5000 }) => {
    const [progress, setProgress] = useState(0);
    const animationRef = useRef(null);
    const startTimeRef = useRef(null);

    const animate = (timestamp) => {
        if (!startTimeRef.current) startTimeRef.current = timestamp;
        const elapsedTime = timestamp - startTimeRef.current;
        const newProgress = Math.min((elapsedTime / duration) * 100, 100);
        setProgress(newProgress);

        if (newProgress < 100) {
            animationRef.current = requestAnimationFrame(animate);
        } else {
            // Reset for looping
            startTimeRef.current = null;
            animationRef.current = requestAnimationFrame(animate);
        }
    };

    useEffect(() => {
        if (isActive) {
            animationRef.current = requestAnimationFrame(animate);
        } else {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            setProgress(isFinished ? 100 : 0);
        }

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [isActive, isFinished]);

    const getBarColor = () => {
        if (isFinished) return "rgba(255, 255, 255, 0.5)";
        if (isActive) return "linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75))";
        return "rgba(255, 255, 255, 0.25)";
    };

    const containerStyle = {
        width: "100%",
        height: "7px",
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.25)",
    };

    const progressStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: !isActive ? (isFinished ? "100%" : "0%") : `${progress}%`,
        background: getBarColor(),
        borderRadius: "10px",
        transition: isActive ? "none" : "width 0.3s ease-out",
    };

    return (
        <div style={containerStyle}>
            <div style={progressStyle}></div>
        </div>
    );
};

export default SlideProgress; 