import React, { useRef, useEffect, useState } from 'react';
import { useCart } from '../context/CartContext';
import { ShopifyService } from '../services/shopifyService';

const Cart = ({ isOpen, onClose }) => {
    const { cart, removeFromCart, updateQuantity, getCartTotal } = useCart();
    const cartRef = useRef(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isCheckingOut, setIsCheckingOut] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cartRef.current && !cartRef.current.contains(event.target) && isOpen) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleCheckout = async () => {
        if (isCheckingOut || cart.length === 0) return;

        setIsCheckingOut(true);
        try {
            const checkoutUrl = await ShopifyService.createCheckout(cart);
            window.location.href = checkoutUrl; // Redirect to Shopify checkout
        } catch (error) {
            console.error('Checkout error:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsCheckingOut(false);
        }
    };

    return (
        <div
            ref={cartRef}
            className={`fixed left-0 w-full bg-[#4E8926] transition-all duration-300 ease-in-out z-40
                ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}
            style={{ height: '50vh' }}
        >
            {/* Hoverable Image Display - Smaller dimensions */}
            {hoveredItem && (
                <div className="hidden md:block absolute bottom-[30px] right-[30px] z-50">
                    <img
                        src={hoveredItem.image.url}
                        alt={hoveredItem.title}
                        className="w-[80px] h-[104px] object-cover"
                    />
                </div>
            )}

            {cart.length > 0 && (
                <button
                    onClick={handleCheckout}
                    disabled={isCheckingOut}
                    className="w-full px-8 py-3 bg-black text-white text-xs tracking-wider 
                            hover:bg-[#323232] transition-opacity duration-200 mb-[30px]
                            disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isCheckingOut ? 'REDIRECTING TO CHECKOUT...' : 'CHECKOUT'}
                </button>
            )}
            <div className="w-full md:px-[30px] px-4">
                {/* Cart Items Table */}
                <div className="overflow-y-auto pt-[30px]">
                    {cart.length === 0 ? (
                        <div className="text-xs uppercase tracking-wider">Your cart is empty</div>
                    ) : (
                        <>
                            {/* Table Headers */}
                            <div className="hidden md:w-full md:grid grid-cols-4 gap-4 text-xs tracking-wider mb-4 pb-2 border-b border-black ">
                                <div className='text-color-black opacity-50'>PRODUCT</div>
                                <div className='text-color-black opacity-50'>SIZE</div>
                                <div className='text-color-black opacity-50'>QUANTITY</div>
                                <div className="text-right text-color-black opacity-50">PRICE</div>
                            </div>

                            {/* Table Rows */}
                            <div className="flex flex-col gap-4">
                                {cart.map((item) => (
                                    <div key={item.variantId}>
                                        <div
                                            className="hidden md:grid grid-cols-4 gap-4 items-center text-xs tracking-wider pb-2 border-b border-black"
                                            onMouseEnter={() => setHoveredItem(item)}
                                            onMouseLeave={() => setHoveredItem(null)}
                                        >
                                            <div>{item.title}</div>
                                            <div className="flex items-center gap-2">
                                                {item.variantTitle.split(' / ')[0]}
                                                <button className="opacity-50 hover:opacity-100">CHANGE</button>
                                            </div>
                                            <div className="flex items-center gap-4">
                                                <span>{item.quantity}</span>
                                                <div className="flex gap-2">
                                                    <button onClick={() => updateQuantity(item.variantId, item.quantity + 1)}
                                                        className="opacity-50 hover:opacity-100">
                                                        ADD
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (item.quantity > 1) {
                                                                updateQuantity(item.variantId, item.quantity - 1);
                                                            } else {
                                                                removeFromCart(item.variantId);
                                                            }
                                                        }}
                                                        className="opacity-50 hover:opacity-100"
                                                    >
                                                        REMOVE
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                {item.price.amount * item.quantity} {item.price.currencyCode}
                                            </div>
                                        </div>

                                        <div className="md:hidden border-b border-black pb-4 mb-4">
                                            <div className="grid grid-cols-[1fr_1fr_1fr] items-start mb-[30px]">
                                                <div className="text-[12px] opacity-50">PRODUCT</div>
                                                <div className="text-[12px]">{item.title}</div>
                                                <div className="text-[12px] text-right">
                                                    {item.price.amount * item.quantity} {item.price.currencyCode}
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-[1fr_1fr_1fr] items-center">
                                                <div className="text-[12px] opacity-50">SIZE</div>
                                                <div className="text-[12px]">{item.variantTitle.split(' / ')[0]}</div>
                                                <div></div>
                                            </div>

                                            <div className="grid grid-cols-[1fr_0.75fr_1fr_0.25fr] items-center">
                                                <div className="text-[12px] opacity-50">QUANTITY</div>
                                                <div className="text-[12px]">{item.quantity}</div>
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => updateQuantity(item.variantId, item.quantity + 1)}
                                                        className="text-[12px] opacity-50 hover:opacity-100"
                                                    >
                                                        ADD
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (item.quantity > 1) {
                                                                updateQuantity(item.variantId, item.quantity - 1);
                                                            } else {
                                                                removeFromCart(item.variantId);
                                                            }
                                                        }}
                                                        className="text-[12px] opacity-50 hover:opacity-100"
                                                    >
                                                        REMOVE
                                                    </button>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Footer with Total - Moved inside the cart items container */}
                            <div className="mt-[30px] border-t border-b border-black py-[10px]">
                                <div className="flex justify-between items-center">
                                    <span className="text-xs tracking-wider">TOTAL</span>
                                    <span className="text-xs tracking-wider">{getCartTotal()} USD</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Cart;
