export const articles = {
    'spring-summer-2024': {
        image: '/assets/images/article1.png',
        date: 'JANUARY 15, 2024',
        subtitle: 'COLLECTION',
        title: 'SPRING SUMMER 2024',
        images: [
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' }
        ],
        content: [
            {
                text: "Spring Summer 2024 marks a pivotal moment in contemporary fashion, where the boundaries between minimalism and artistic expression converge. This collection represents not just a seasonal offering, but a philosophical exploration of how we interact with clothing in the modern world. Through careful consideration of form, material, and function, each piece tells a story of innovation and tradition intertwined."
            },
            {
                heading: "THE COLLECTION",
                text: "At the heart of the Spring Summer 2024 collection lies a deep understanding of material and form. Each garment is meticulously crafted using innovative techniques that push the boundaries of traditional tailoring. The silhouettes draw inspiration from architectural forms, creating a dialogue between the body and its surrounding space. Premium materials, sourced from the finest mills in Japan and Italy, undergo extensive development to achieve the perfect weight, drape, and texture. The result is a collection that feels both timeless and distinctly contemporary, where each piece serves as a building block for the modern wardrobe."
            },
            {
                heading: "INSPIRATION",
                text: "The inspiration for this collection emerged from a profound exploration of light and shadow in modernist architecture. Studying how natural light interacts with concrete structures throughout the day revealed a subtle play of tones and textures that became the foundation for our color palette. This observation led to the development of custom-dyed fabrics that capture these nuanced variations, creating garments that seem to shift and evolve with the changing light. The architectural influence extends beyond aesthetics, informing the very construction of each piece. Traditional tailoring techniques are deconstructed and reimagined, resulting in garments that honor craftsmanship while embracing innovation."
            },
            {
                heading: "SUSTAINABILITY",
                text: "Environmental consciousness remains at the forefront of our design process. This season, we've partnered with innovative textile manufacturers who share our commitment to sustainability. By implementing advanced water recycling systems and utilizing solar energy in production, we've significantly reduced our environmental impact. Each garment is created with longevity in mind, both in terms of style and durability. We've also introduced a new line of biodegradable packaging, further reducing our carbon footprint while maintaining the luxury experience our customers expect."
            },
            {
                heading: "FUTURE VISION",
                text: "As we look to the future, this collection represents more than just clothing; it embodies our vision for the future of fashion. By combining traditional craftsmanship with cutting-edge technology, we're creating garments that respect the past while embracing the future. The Spring Summer 2024 collection serves as a testament to our commitment to innovation, sustainability, and timeless design. Each piece is designed to evolve with its wearer, becoming an integral part of their personal style journey."
            }
        ]
    },
    'modern-heritage': {
        image: '/assets/images/article2.png',
        date: 'DECEMBER 10, 2023',
        subtitle: 'EDITORIAL',
        title: 'MODERN HERITAGE',
        images: [
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' }
        ],
        content: [
            {
                text: "Exploring the intersection of traditional craftsmanship and contemporary design, Modern Heritage represents our commitment to preserving artisanal techniques while pushing the boundaries of modern fashion."
            },
            {
                heading: "CRAFTSMANSHIP",
                text: "Each piece in this collection is a testament to the skilled artisans who bring our designs to life. Traditional techniques are reimagined through a contemporary lens, creating pieces that are both timeless and forward-looking."
            },
            {
                heading: "MATERIALS",
                text: "We've sourced the finest materials from sustainable producers, ensuring that each garment not only looks and feels exceptional but also meets our strict environmental standards."
            }
        ]
    },
    'making-of-ss24': {
        image: '/assets/images/article3.png',
        date: 'NOVEMBER 28, 2023',
        subtitle: 'BEHIND THE SCENES',
        title: 'MAKING OF SS24',
        images: [
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' }
        ],
        content: [
            {
                text: "A glimpse into the creative process behind our Spring Summer 2024 collection. From initial sketches to final fittings, this is the story of how our vision came to life."
            },
            {
                heading: "THE PROCESS",
                text: "Our design team spent months perfecting each silhouette, ensuring that every piece in the collection serves both form and function. The development process involved countless iterations and refinements."
            },
            {
                heading: "PRODUCTION",
                text: "Working closely with our manufacturing partners, we implemented innovative techniques to minimize waste while maintaining the highest standards of quality. Each piece is carefully constructed with attention to every detail."
            }
        ]
    },
    'sustainable-future': {
        image: '/assets/images/article1.png',
        date: 'NOVEMBER 15, 2023',
        subtitle: 'SUSTAINABILITY',
        title: 'TOWARDS A SUSTAINABLE FUTURE',
        images: [
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' }
        ],
        content: [
            {
                text: "As we move towards a more sustainable future in fashion, SIMSIM is committed to leading the way with innovative approaches to production and design. Our journey towards sustainability is more than just a commitment—it's a complete reimagining of how luxury fashion can coexist with environmental responsibility."
            },
            {
                heading: "INNOVATION",
                text: "Our research and development team has been working tirelessly to develop new sustainable materials that don't compromise on quality or aesthetic. From biodegradable packaging to recycled fabrics that maintain the highest standards of luxury, every aspect of our production is being revolutionized with sustainability in mind."
            },
            {
                heading: "COMMITMENT",
                text: "We've set ambitious goals for carbon neutrality by 2025, implementing comprehensive strategies across our supply chain. This includes partnering with local artisans to reduce transportation emissions and investing in renewable energy sources for our production facilities."
            },
            {
                heading: "IMPACT",
                text: "The fashion industry's environmental impact is significant, but we believe that through innovation and commitment, we can create positive change. Our initiatives have already resulted in a 40% reduction in water usage and a 30% decrease in carbon emissions compared to traditional production methods."
            }
        ]
    },
    'artisan-collaboration': {
        image: '/assets/images/article2.png',
        date: 'OCTOBER 28, 2023',
        subtitle: 'COLLABORATION',
        title: 'ARTISAN COLLABORATION SERIES',
        images: [
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' }
        ],
        content: [
            {
                text: "SIMSIM's latest initiative brings together traditional craftsmanship with contemporary design through our Artisan Collaboration Series. This unique project connects master artisans from around the world with our design team, creating pieces that honor heritage while pushing boundaries."
            },
            {
                heading: "THE ARTISANS",
                text: "We've partnered with master craftspeople from Japan, Italy, and Peru, each bringing generations of expertise to this collaboration. Their unique techniques and traditional methods have been carefully integrated into our contemporary designs, creating pieces that tell a story of cultural exchange and artistic innovation."
            },
            {
                heading: "THE PROCESS",
                text: "Each piece in the collection undergoes a careful development process, combining traditional handcrafting techniques with modern production methods. The result is a seamless blend of old and new, where ancient craftsmanship meets contemporary design sensibilities."
            },
            {
                heading: "CULTURAL EXCHANGE",
                text: "This collaboration represents more than just a fashion collection—it's a cultural exchange that preserves and celebrates traditional craftsmanship. By bringing these techniques into a modern context, we're ensuring their relevance and survival for future generations."
            }
        ]
    },
    'design-philosophy': {
        image: '/assets/images/article3.png',
        date: 'OCTOBER 15, 2023',
        subtitle: 'PHILOSOPHY',
        title: 'THE SIMSIM DESIGN PHILOSOPHY',
        images: [
            { url: '/assets/images/article3.png' },
            { url: '/assets/images/article1.png' },
            { url: '/assets/images/article2.png' },
            { url: '/assets/images/article3.png' }
        ],
        content: [
            {
                text: "At the core of SIMSIM lies a design philosophy that challenges conventional fashion paradigms. Our approach combines minimalist aesthetics with innovative construction techniques, creating pieces that transcend seasonal trends."
            },
            {
                heading: "DESIGN PRINCIPLES",
                text: "Our design process begins with three fundamental principles: functionality, sustainability, and timeless aesthetics. Each piece must serve a purpose, minimize its environmental impact, and maintain its relevance beyond seasonal trends. This trinity of principles guides every decision in our creative process."
            },
            {
                heading: "MATERIAL INNOVATION",
                text: "We believe that true innovation in fashion starts with materials. Our research and development team constantly explores new sustainable fabrics and innovative construction techniques. From biodegradable synthetics to advanced natural fibers, our material selection process prioritizes both environmental responsibility and performance."
            },
            {
                heading: "AESTHETIC EVOLUTION",
                text: "The SIMSIM aesthetic is constantly evolving while maintaining its core identity. We draw inspiration from architectural forms, natural phenomena, and cultural movements, translating these influences into garments that feel both familiar and revolutionary. Our design team works to create pieces that challenge perceptions while remaining inherently wearable."
            },
            {
                heading: "FUTURE DIRECTION",
                text: "As we look to the future, our design philosophy continues to evolve. We're exploring new technologies like 3D printing and zero-waste pattern cutting, while maintaining our commitment to craftsmanship and quality. This balance between innovation and tradition defines the SIMSIM approach to modern fashion design."
            }
        ]
    }
    // Add more articles as needed
}; 