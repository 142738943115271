import React from 'react';
import { useNavigate } from 'react-router-dom';
import PathNavigation from './PathNavigation';
import { articles } from '../data/articles';
import Footer from './Footer';

const Explore = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const navigate = useNavigate();

    // Convert articles object to array for mapping
    const articlesArray = Object.entries(articles).map(([slug, article]) => ({
        id: slug,
        image: article.image,
        subtitle: article.subtitle,
        title: article.title,
        slug: slug
    }));

    const handleArticleClick = (slug) => {
        navigate(`/explore/${slug}`);
    };

    return (
        <div className="relative overflow-hidden">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen">
                    <div className="pt-[170px] px-0">
                        {/* Articles Grid */}
                        <div className="grid grid-cols-2 md:grid-cols-3 cursor-pointer">
                            {articlesArray.map((article) => (
                                <div
                                    key={article.id}
                                    className="flex flex-col"
                                    onClick={() => handleArticleClick(article.slug)}
                                >
                                    {/* Image Container with Text Overlay */}
                                    <div className="relative w-full aspect-[3/4] overflow-hidden group">
                                        <img
                                            src={article.image}
                                            alt={article.title}
                                            className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-50"
                                        />
                                        {/* Text Overlay */}
                                        <div className="absolute top-[8px] md:top-[30px] left-[8px] md:left-[30px]">
                                            <div className="text-xs md:tracking-wider text-white">
                                                {article.subtitle}
                                            </div>
                                            <div className="text-xs tracking-wider text-white">
                                                {article.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Path Navigation */}
                        <PathNavigation />

                        {/* Footer */}
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Explore; 