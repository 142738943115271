import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    const [isCreditsOpen, setIsCreditsOpen] = useState(false);

    return (
        <footer className="relative bg-[#4E8926] pt-[60px] pb-[10px] md:px-[30px]">
            {/* Desktop layout */}
            <div className="hidden md:flex justify-between mt-[120px]">
                {/* Left column */}
                <div className="flex flex-col justify-end gap-[30px] text-left h-full">
                    <div className="flex flex-col gap-[30px]">
                        <div className="flex flex-col">
                            <Link to="/brand" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">ABOUT</Link>
                            <Link to="/contact" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">CONTACT</Link>
                            <Link to="/stockists" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">STOCKISTS</Link>
                        </div>

                        <div className="flex flex-col">
                            <Link to="/privacy-policy" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">PRIVACY POLICY</Link>
                            <Link to="/shipping-returns" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">SHIPPING AND RETURNS</Link>
                            <Link to="/terms-and-conditions" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">TERMS AND CONDITIONS</Link>
                        </div>

                        <div className="flex flex-col">
                            <div className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">COUNTRY: UNITED STATES</div>
                            <div className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">CURRENCY: USD $</div>
                        </div>

                        <div className="flex flex-col">
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">INSTAGRAM</a>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">FACEBOOK</a>
                            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">TIKTOK</a>
                        </div>
                    </div>

                    {/* Newsletter at the very end */}
                    <div className="mt-auto">
                        <Link to="/newsletter" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">NEWSLETTER</Link>
                    </div>
                </div>

                {/* Center logo */}
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <img
                        src="/assets/images/logo_footer.svg"
                        alt="SIMSIM Logo"
                        className="h-[42.9px]"
                    />
                </div>

                {/* Right side */}
                <div className="flex flex-col justify-end text-left">
                    {isCreditsOpen && (
                        <div className="flex flex-col">
                            <span className="text-xs tracking-wider">DESIGN AND AD: SPECS47</span>
                            <span className="text-xs tracking-wider">DEVELOPMENT: RESILIENCIA STUDIO</span>
                        </div>
                    )}
                    <div className="flex items-center gap-[200px]">
                        <div className="w-[60px]">
                            <button
                                onClick={() => setIsCreditsOpen(!isCreditsOpen)}
                                className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200"
                            >
                                {isCreditsOpen ? 'CLOSE' : 'CREDITS'}
                            </button>
                        </div>
                        <span className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">© 2024 SIMSIM. ALL RIGHTS RESERVED.</span>
                    </div>
                </div>
            </div>

            {/* Mobile layout */}
            <div className="flex flex-col justify-end h-full md:hidden px-[16px]">
                {/* Logo for mobile */}
                <div className="mx-auto mb-[60px]">
                    <img
                        src="/assets/images/logofooter.png"
                        alt="SIMSIM Logo"
                        className="h-[42.9px]"
                    />
                </div>

                {/* Two-column layout for mobile */}
                <div className="flex h-full">
                    {/* Left column */}
                    <div className="flex flex-col gap-[30px] w-1/2">
                        <div className="flex flex-col">
                            <Link to="/brand" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">ABOUT</Link>
                            <Link to="/contact" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">CONTACT</Link>
                            <Link to="/stockists" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">STOCKISTS</Link>
                        </div>

                        <div className="flex flex-col">
                            <Link to="/privacy-policy" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">PRIVACY POLICY</Link>
                            <Link to="/shipping-returns" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">SHIPPING AND RETURNS</Link>
                            <Link to="/terms-and-conditions" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">TERMS AND CONDITIONS</Link>
                        </div>

                        <div className="flex flex-col">
                            <div className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">COUNTRY: UNITED STATES</div>
                            <div className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">CURRENCY: USD $</div>
                        </div>

                        <div className="flex flex-col">
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">INSTAGRAM</a>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">FACEBOOK</a>
                            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">TIKTOK</a>
                        </div>

                        <Link to="/newsletter" className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200">NEWSLETTER</Link>
                    </div>

                    {/* Right column */}
                    <div className="flex flex-col justify-end items-end w-1/2">
                        <div className="relative">
                            {isCreditsOpen && (
                                <div className="absolute bottom-full right-0 mb-2 w-[250px]">
                                    <div className="text-xs tracking-wider text-right">DESIGN AND AD: SPECS47</div>
                                    <div className="text-xs tracking-wider text-right">DEVELOPMENT: RESILIENCIA STUDIO</div>
                                </div>
                            )}
                            <div className="flex flex-col items-end gap-2 mt-auto">
                                <button
                                    onClick={() => setIsCreditsOpen(!isCreditsOpen)}
                                    className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200"
                                >
                                    {isCreditsOpen ? 'CLOSE' : 'CREDITS'}
                                </button>
                                <div className="text-xs tracking-wider">© 2024</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
