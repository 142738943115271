import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ShopifyService } from '../services/shopifyService';
import MobileMenu from './MobileMenu';
import { useCart } from '../context/CartContext';
import RelatedItems from './RelatedItems';
import PathNavigation from './PathNavigation';
import Footer from './Footer';

const ProductPage = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const { handle } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [selectedSize, setSelectedSize] = useState('SMALL');
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const { addToCart } = useCart();
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([]);

    const formatPrice = (amount, currencyCode) => {
        return `${Math.round(amount)} ${currencyCode}`;
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productData = await ShopifyService.getProductByHandle(handle);
                setProduct(productData);
                // Set default variant
                const defaultVariant = productData.variants.edges.find(
                    variant => variant.node.title.toUpperCase() === 'SMALL'
                )?.node || productData.variants.edges[0]?.node;
                setSelectedVariant(defaultVariant);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [handle]);

    useEffect(() => {
        const fetchRelatedProducts = async () => {
            try {
                // Fetch products from the same collection or with similar tags
                const products = await ShopifyService.getProducts(6); // Adjust this to fetch related products
                setRelatedProducts(products);
            } catch (err) {
                console.error('Error fetching related products:', err);
            }
        };

        if (product) {
            fetchRelatedProducts();
        }
    }, [product]);

    const handleSizeChange = (size) => {
        const variant = product.variants.edges.find(
            variant => variant.node.title.toUpperCase() === size
        )?.node;
        setSelectedSize(size);
        setSelectedVariant(variant);
    };

    const toggleDropdown = (section) => {
        setActiveDropdown(activeDropdown === section ? null : section);
    };

    const handleAddToCart = () => {
        if (!selectedVariant || isAddingToCart) return;

        setIsAddingToCart(true);
        addToCart(product, selectedVariant);

        // Show feedback (you might want to add a toast notification here)
        setTimeout(() => {
            setIsAddingToCart(false);
        }, 500);
    };

    if (loading) return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-xl font-newedge">Loading...</div>
        </div>
    );

    if (error) return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-red-500 text-xl font-newedge">Error: {error}</div>
        </div>
    );

    if (!product) return null;

    const images = product.images.edges;
    const sizes = [...new Set(product.variants.edges.map(edge => edge.node.title.toUpperCase()))];

    return (
        <div className="relative overflow-hidden">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen">
                    <div className="flex flex-col md:flex-row relative">
                        {/* Main Image Section */}
                        <div className="w-full md:w-1/2">
                            {/* Main Image - Reduced height for mobile */}
                            <div className="h-[65vh] md:h-screen">
                                <img
                                    src={images[activeImageIndex].node.url}
                                    alt={images[activeImageIndex].node.altText || product.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {/* Mobile Thumbnails - Remains the same */}
                            <div className="flex md:hidden gap-0 px-0">
                                {images.slice(0, 4).map((image, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setActiveImageIndex(index)}
                                        className={`w-[25%] aspect-square overflow-hidden transition-opacity duration-200 
                                            ${activeImageIndex === index ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
                                    >
                                        <img
                                            src={image.node.url}
                                            alt={image.node.altText || `Product view ${index + 1}`}
                                            className="w-full h-full object-cover"
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Desktop Thumbnail Navigation - Keep as is */}
                        <div className="hidden md:flex flex-col justify-end gap-0 absolute left-[50%] bottom-0">
                            {images.slice(0, 4).map((image, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveImageIndex(index)}
                                    className={`w-[80px] h-[100px] overflow-hidden transition-opacity duration-200 
                                        ${activeImageIndex === index ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
                                >
                                    <img
                                        src={image.node.url}
                                        alt={image.node.altText || `Product view ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                </button>
                            ))}
                        </div>

                        {/* Product Info - Updated mobile spacing */}
                        <div className="w-full md:w-1/2 md:h-screen flex items-end justify-end">
                            <div className="w-full max-w-[500px] px-4 md:px-0 md:pr-[30px] pb-[60px] pt-[30px] md:pt-0">
                                {/* Product Title and Price */}
                                <div className="flex justify-between items-start mb-[30px]">
                                    <h1 className="text-xs tracking-wider">
                                        {product.title.toUpperCase()}
                                    </h1>
                                    <span className="text-xs tracking-wider">
                                        {formatPrice(selectedVariant.price.amount, selectedVariant.price.currencyCode)}
                                    </span>
                                </div>

                                {/* Size Selection */}
                                <div className="mb-[30px]">
                                    <div className="flex gap-4">
                                        {sizes.map((size) => (
                                            <button
                                                key={size}
                                                onClick={() => handleSizeChange(size)}
                                                className={`text-xs tracking-wider
                                                    ${selectedSize === size ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
                                            >
                                                {size}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* Dropdowns Container */}
                                <div className="flex flex-col mb-[30px] min-h-[180px]">
                                    {['INFORMATION', 'DETAIL', 'DELIVERY AND RETURN'].map((section, index) => (
                                        <div key={section} className="mb-0">
                                            <button
                                                onClick={() => toggleDropdown(section)}
                                                className="w-full text-left text-xs tracking-wider hover:opacity-75 transition-opacity"
                                            >
                                                {section}
                                            </button>
                                            {activeDropdown === section && (
                                                <div className="text-xs tracking-wider py-4">
                                                    {section === 'INFORMATION' && product.description}
                                                    {section === 'DETAIL' && 'Product details...'}
                                                    {section === 'DELIVERY AND RETURN' && 'Shipping information...'}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Add to Cart Button */}
                                <div className="mt-[30px]">
                                    <button
                                        onClick={handleAddToCart}
                                        disabled={isAddingToCart || !selectedVariant.availableForSale}
                                        className={`w-full px-8 py-3 bg-black text-white text-xs tracking-wider 
                                            hover:bg-opacity-50 transition-all duration-200 rounded-[70px] relative
                                            ${!selectedVariant.availableForSale ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        <span>{isAddingToCart ? 'ADDING...' : 'ADD TO CART'}</span>
                                        {selectedVariant.availableForSale && (
                                            <span className="absolute right-8 text-xs tracking-wider text-white opacity-50">
                                                {selectedVariant.quantityAvailable || 2} ITEMS LEFT
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Related Items Section */}
                    <RelatedItems products={relatedProducts} currentProductId={product.id} />

                    {/* Path Navigation */}
                    <PathNavigation />

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
            <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
        </div>
    );
};

export default ProductPage; 