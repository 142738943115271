import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PathNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Format the path to uppercase and remove the leading slash
    const pathSegments = location.pathname
        .split('/')
        .filter(Boolean)
        .map(segment => segment.toUpperCase());

    // Create the full path with HOME
    const formattedPath = ['HOME', ...pathSegments].join('/');

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    return (
        <div className="w-full h-[100px] md:h-[210px] flex items-end">
            <div className="w-full px-4 md:px-[30px] pb-[10px]">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <button
                            onClick={handleHomeClick}
                            className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200"
                        >
                            HOME
                        </button>
                        {pathSegments.length > 0 && (
                            <>
                                <span className="text-xs tracking-wider mx-1">/</span>
                                <span className="text-xs tracking-wider">
                                    {pathSegments.join('/')}
                                </span>
                            </>
                        )}
                    </div>
                    <button
                        onClick={handleScrollToTop}
                        className="text-xs tracking-wider hover:opacity-50 transition-opacity duration-200"
                    >
                        BACK TO TOP
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PathNavigation; 