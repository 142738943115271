import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PathNavigation from './PathNavigation';
import { articles } from '../data/articles';
import Footer from './Footer';

const ExploreArticle = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    const article = articles[slug];

    useEffect(() => {
        // Redirect to explore page if article doesn't exist
        if (!article) {
            navigate('/explore');
        }
    }, [article, navigate]);

    if (!article) return null;

    return (
        <div className="relative">
            <div
                className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'
                    }`}
            >
                {/* Main Container */}
                <div className="md:flex">
                    {/* Content Column - Reduced width */}
                    <div className="w-full md:w-[45%]">
                        {/* Mobile Main Image */}
                        <div className="w-full h-[65vh] md:hidden">
                            <img
                                src={article.images[activeImageIndex].url}
                                alt={article.title}
                                className="w-full h-full object-cover"
                            />
                        </div>

                        {/* Mobile Thumbnails */}
                        <div className="flex md:hidden gap-0 px-0">
                            {article.images.slice(0, 4).map((image, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveImageIndex(index)}
                                    className={`w-[25%] aspect-square overflow-hidden transition-opacity duration-200 
                                        ${activeImageIndex === index
                                            ? 'opacity-100'
                                            : 'opacity-50 hover:opacity-75'
                                        }`}
                                >
                                    <img
                                        src={image.url}
                                        alt={`View ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                </button>
                            ))}
                        </div>

                        {/* Date and Subtitle - Desktop */}
                        <div className="hidden md:flex md:flex-row px-[30px] space-x-10 pt-[140px]">
                            <div className="text-xs tracking-wider mb-1">{article.date}</div>
                            <div className="text-xs tracking-wider">{article.subtitle}</div>
                        </div>

                        {/* Mobile Top Info */}
                        <div className="md:hidden px-4 mt-[120px]">
                            <div className="text-xs tracking-wider mb-1">{article.date}</div>
                            <div className="text-xs tracking-wider">{article.subtitle}</div>
                        </div>

                        {/* Content */}
                        <div className="w-full max-w-[455px] px-4 md:px-[30px] pb-[30px] md:pb-[60px] md:mt-[30vh]">
                            <div className="flex flex-col gap-[30px]">
                                {article.content.map((section, index) => (
                                    <div key={index}>
                                        {section.heading && (
                                            <h2 className="text-xs tracking-wider mb-4 font-newedge">
                                                {section.heading}
                                            </h2>
                                        )}
                                        {section.subheading && (
                                            <h3 className="text-xs tracking-wider mb-4 font-newedge">
                                                {section.subheading}
                                            </h3>
                                        )}
                                        {section.text && (
                                            <p className="text-xs leading-[15px] font-newedge font-normal">
                                                {section.text}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Image and Thumbnails Column - Combined width 60% */}
                    <div className="hidden md:flex md:w-[56%]">
                        {/* Thumbnails Column */}
                        <div className="w-[80px]">
                            <div className="sticky top-0 h-screen flex flex-col justify-end">
                                {article.images.slice(0, 4).map((image, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setActiveImageIndex(index)}
                                        className={`w-full h-[100px] overflow-hidden transition-opacity duration-200 
                                            ${activeImageIndex === index ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
                                    >
                                        <img
                                            src={image.url}
                                            alt={`View ${index + 1}`}
                                            className="w-full h-full object-cover"
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Main Image - Set to fill remaining space */}
                        <div className="w-[calc(100%-80px)]">
                            <div className="sticky top-0 h-screen">
                                <img
                                    src={article.images[activeImageIndex].url}
                                    alt={article.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Path Navigation */}
                <PathNavigation />

                {/* Footer */}
                <Footer />
            </div>
        </div>
    );
};

export default ExploreArticle;