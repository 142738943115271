import { GraphQLClient } from 'graphql-request';

const endpoint = `https://${process.env.REACT_APP_SHOPIFY_STORE_DOMAIN}/api/${process.env.REACT_APP_SHOPIFY_API_VERSION}/graphql.json`;

console.log('Shopify API Endpoint:', endpoint);
console.log('Store Domain:', process.env.REACT_APP_SHOPIFY_STORE_DOMAIN);
console.log('API Version:', process.env.REACT_APP_SHOPIFY_API_VERSION);

export const shopifyClient = new GraphQLClient(endpoint, {
    headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json',
    },
}); 