import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ShopifyService } from './services/shopifyService';
import Home from './components/Home';
import Shop from './components/Shop';
import MobileMenu from './components/MobileMenu';
import ProductPage from './components/ProductPage';
import { CartProvider } from './context/CartContext';
import Navbar from './components/Navbar';
import Brand from './components/Brand';
import Explore from './components/Explore';
import ExploreArticle from './components/ExploreArticle';
import Cart from './components/Cart';
import PrivacyPolicy from './components/PrivacyPolicy';
import ShippingReturns from './components/ShippingReturns';
import TermsAndConditions from './components/TermsAndConditions';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import Stockists from './components/Stockists';
import Search from './components/Search';

function App() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await ShopifyService.getProducts(12);
        setProducts(fetchedProducts);
        console.log('Fetched products:', fetchedProducts);
      } catch (err) {
        setError(err.message);
        console.error('Error details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) return (
    <div className="flex items-center justify-center min-h-screen px-site">
      <div className="text-xl font-newedge">Loading...</div>
    </div>
  );

  if (error) return (
    <div className="flex items-center justify-center min-h-screen px-site">
      <div className="text-red-500 text-xl font-newedge">Error: {error}</div>
    </div>
  );

  return (
    <CartProvider>
      <Router>
        <AppContent
          products={products}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        >
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/shop"
              element={
                <Shop
                  products={products}
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/product/:handle"
              element={
                <ProductPage
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/brand"
              element={
                <Brand
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/explore"
              element={
                <Explore
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/explore/:slug"
              element={
                <ExploreArticle
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/shipping-returns"
              element={
                <ShippingReturns
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <TermsAndConditions
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="/stockists"
              element={
                <Stockists
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFound
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              }
            />
          </Routes>
        </AppContent>
      </Router>
    </CartProvider>
  );
}

function AppContent({ products, isMobileMenuOpen, setIsMobileMenuOpen, children }) {
  const location = useLocation();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const shouldNavbarBeDark = () => {
    const paths = ['/shop', '/product', '/brand', '/explore', '/privacy-policy', '/shipping-returns', '/terms-and-conditions'];
    return paths.some(path => location.pathname.startsWith(path)) || !paths.some(path => location.pathname === '/');
  };

  useEffect(() => {
    if (isCartOpen || isSearchOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isCartOpen, isSearchOpen]);

  return (
    <div className="relative">
      <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
      <Search isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
      <div className={`transition-transform duration-300 ease-in-out ${isCartOpen || isSearchOpen ? 'translate-y-[50vh]' : ''}`}>
        <Navbar
          darkMode={shouldNavbarBeDark()}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          isCartOpen={isCartOpen}
          setIsCartOpen={setIsCartOpen}
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
        >
          {children}
          <MobileMenu
            isOpen={isMobileMenuOpen}
            onClose={() => setIsMobileMenuOpen(false)}
            setIsSearchOpen={setIsSearchOpen}
          />
        </Navbar>
      </div>
    </div>
  );
}

export default App;
