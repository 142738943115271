import React from 'react';
import PathNavigation from './PathNavigation';
import Footer from './Footer';

const Brand = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    return (
        <div className="relative overflow-hidden">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen md:h-screen flex flex-col md:flex-row">
                    {/* Image Section - Updated height */}
                    <div className="w-full h-[80vh] md:hidden">
                        <img
                            src="/assets/images/brand.png"
                            alt="SIMSIM Brand"
                            className="w-full h-full object-cover"
                        />
                    </div>

                    {/* Left Content */}
                    <div className="w-full md:w-1/2 md:h-full flex items-end">
                        <div className="w-full max-w-[455px] px-4 md:px-[30px] pb-[30px] mt-[120px] md:mt-0">
                            <div className="flex flex-col gap-[30px]">
                                {/* Introduction Section */}
                                <div>
                                    <h2 className="text-xs tracking-wider mb-4 font-newedge">INTRODUCTION</h2>
                                    <p className="text-xs leading-[15px] font-newedge font-normal">
                                        A Ready–To–Wear collection presented by Jovanna. Exploring the lines between form and function driven by experimental approaches, SIMSIM pushes the semantic dialogue within the creative industry. A subversive Scandinavian and monochromatic aesthetic, combined with custom developed materials, fabrics and trimmings, SIMSIM entices a high attention to detail throughout every branch. Designer Jovanna draws on thought provoking themes and a curiosity about creative expression reflected in every collection by SIMSIM.
                                    </p>
                                </div>

                                {/* Foundation Section */}
                                <div>
                                    <h2 className="text-xs tracking-wider mb-4 font-newedge">FOUNDATION</h2>
                                    <p className="text-xs leading-[15px] font-newedge font-normal">
                                        Since the first collection was showcased in Milan during spring / summer 17 season, SIMSIM has continually expropriated its exploration of the creative milieu. The aesthetic is carefully developed with custom fabrics, hardware trimmings and materials. Each hardware detail is created from the bottom up with form and function in mind. Experimentation and a high attention to detail is ensured by partnering only with the best in their category and industry. A tight–bond with all partners allows for a coherent evolution of the brand aesthetic. With painstaking attention to detail each garment is conceived from a collective ideation for each season standing in relation to the brand as a whole.
                                    </p>
                                </div>

                                {/* Future Section */}
                                <div>
                                    <h2 className="text-xs tracking-wider mb-4 font-newedge">FUTURE</h2>
                                    <p className="text-xs leading-[15px] font-newedge font-normal">
                                        The subsequent phase for SIMSIM will see a further cementation of the brand and an expansion of the semantic dialogue with the creative milieu. The core value of the brand will be explored without compromise, through both physical spaces enticing further ideas of form and function, and experiences expressing the innovative nature of the brand. Underlying the continuous organic development is a coherent understanding of the brand developed in close proximity with all partners.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Image - Desktop only */}
                    <div className="hidden md:block w-1/2 h-full">
                        <img
                            src="/assets/images/brand.png"
                            alt="SIMSIM Brand"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>

                {/* Path Navigation */}
                <PathNavigation />

                {/* Footer */}
                <Footer />
            </div>
        </div>
    );
};

export default Brand; 