import React, { useState, useEffect } from 'react';
import Container from './Container';
import SlideProgress from './SlideProgress';

const slides = [
    {
        images: [
            {
                url: '/assets/images/slide1a.png',
                position: 'object-right'
            },
            {
                url: '/assets/images/slide1b.png',
                position: 'object-right'
            }
        ],
        content: {
            topStack: {
                title: "Breaking rules, not traditions",
                subtitle: "SUMMER COLLECTION 2024"
            },
            bottomStack: {
                text1: "DISCOVER THE COLLECTION",
                text2: "SHOP NOW"
            }
        },
        isSplit: true
    },
    {
        images: [
            {
                url: '/assets/images/slide2a.png',
                position: 'object-right'
            },
            {
                url: '/assets/images/slide2b.png',
                position: 'object-right'
            }
        ],
        content: {
            topStack: {
                title: "Elegance Redefined",
                subtitle: "EVENING WEAR COLLECTION"
            },
            bottomStack: {
                text1: "EXPLORE THE COLLECTION",
                text2: "SHOP NOW"
            }
        },
        isSplit: true
    },
    {
        images: [
            {
                url: '/assets/images/slide3.png',
                position: 'object-center'
            }
        ],
        content: {
            topStack: {
                title: "Modern Heritage",
                subtitle: "SIGNATURE PIECES 2024"
            },
            bottomStack: {
                text1: "VIEW THE COLLECTION",
                text2: "SHOP NOW"
            }
        },
        isSplit: false
    }
];

const Slideshow = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [key, setKey] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => {
                const nextSlide = (prev + 1) % slides.length;
                if (nextSlide === 0) {
                    setKey(k => k + 1);
                }
                return nextSlide;
            });
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    const currentSlideData = slides[currentSlide];

    const handleSlideClick = (slide) => {
        console.log('Slide clicked:', slide);
    };

    const Content = ({ className }) => (
        <>
            {/* Desktop Content */}
            <Container className={`absolute left-0 right-0 text-white bottom-[57px] md:block hidden ${className}`}>
                <div className="flex flex-col">
                    {/* Top stack */}
                    <div className="flex flex-col mb-[30px] pointer-events-none text-left">
                        <span className="text-[24px] font-newedge">
                            {currentSlideData.content.topStack.title}
                        </span>
                        <span className="text-[40px] leading-[48px] font-newedge">
                            {currentSlideData.content.topStack.subtitle}
                        </span>
                    </div>

                    {/* Bottom stack - removed the flex and gap, aligned with top text */}
                    <div className="text-left">
                        <div className="mb-[10px]">
                            <button
                                onClick={() => handleSlideClick(currentSlideData)}
                                className="text-[12px] font-newedge cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            >
                                {currentSlideData.content.bottomStack.text1}
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => handleSlideClick(currentSlideData)}
                                className="text-[12px] font-newedge cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            >
                                {currentSlideData.content.bottomStack.text2}
                            </button>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Mobile Content */}
            <div className="md:hidden absolute inset-0 flex items-center px-[16px]">
                <div className="flex flex-col text-white text-left">
                    <span className="text-[12px] font-newedge mb-1">
                        {currentSlideData.content.topStack.title}
                    </span>
                    <span className="text-[32px] leading-[32px] font-newedge">
                        {currentSlideData.content.topStack.subtitle}
                    </span>
                </div>
            </div>
        </>
    );

    return (
        <div className="relative h-screen w-full overflow-hidden">
            <div className="h-full w-full">
                {currentSlideData.isSplit ? (
                    <>
                        {/* Desktop split layout */}
                        <div className="h-full w-full md:flex hidden">
                            {currentSlideData.images.map((image, index) => (
                                <div key={index} className="w-1/2 h-full relative cursor-pointer" onClick={() => handleSlideClick(currentSlideData)}>
                                    <img
                                        src={image.url}
                                        alt={`Slide ${currentSlide + 1} - ${index + 1}`}
                                        className={`w-full h-full ${image.position} object-cover`}
                                    />
                                    {index === 0 && <Content />}
                                </div>
                            ))}
                        </div>
                        {/* Mobile single image layout */}
                        <div className="md:hidden block h-full w-full relative cursor-pointer" onClick={() => handleSlideClick(currentSlideData)}>
                            <img
                                src={currentSlideData.images[0].url}
                                alt={`Slide ${currentSlide + 1}`}
                                className={`w-full h-full ${currentSlideData.images[0].position} object-cover`}
                            />
                            <Content />
                        </div>
                    </>
                ) : (
                    // Full width image layout
                    <div className="w-full h-full relative cursor-pointer" onClick={() => handleSlideClick(currentSlideData)}>
                        <img
                            src={currentSlideData.images[0].url}
                            alt={`Slide ${currentSlide + 1}`}
                            className={`w-full h-full ${currentSlideData.images[0].position} object-cover`}
                        />
                        <Content />
                    </div>
                )}

                {/* Progress Bars */}
                <div className="absolute left-0 right-0 bottom-[20px] z-10 pointer-events-none">
                    <div className="px-site md:block hidden">
                        <div className="flex gap-2">
                            {slides.map((_, index) => (
                                <div key={`${key}-${index}`} className="flex-1">
                                    <SlideProgress
                                        key={`${key}-${index}`}
                                        isActive={currentSlide === index}
                                        isFinished={currentSlide > index}
                                        duration={5000}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="md:hidden px-[16px]">
                        <div className="w-1/3">
                            <SlideProgress
                                key={`${key}-${currentSlide}`}
                                isActive={true}
                                isFinished={false}
                                duration={5000}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slideshow; 