import { shopifyClient } from './shopifyClient';
import { PRODUCTS_QUERY, PRODUCT_BY_HANDLE_QUERY } from './shopifyQueries';

const searchProducts = async (searchQuery) => {
    const query = `
    query searchProducts($query: String!) {
      products(first: 10, query: $query) {
        edges {
          node {
            id
            title
            handle
            images(first: 1) {
              edges {
                node {
                  url
                  altText
                }
              }
            }
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  `;

    const variables = {
        query: searchQuery
    };

    try {
        const response = await shopifyClient.request(query, variables);
        return response.products.edges.map(({ node }) => ({
            id: node.id,
            title: node.title,
            handle: node.handle,
            image: {
                url: node.images.edges[0]?.node.url || '',
                altText: node.images.edges[0]?.node.altText || ''
            },
            price: {
                amount: node.priceRange.minVariantPrice.amount,
                currencyCode: node.priceRange.minVariantPrice.currencyCode
            }
        }));
    } catch (error) {
        console.error('Error searching products:', error);
        throw error;
    }
};

const CREATE_CHECKOUT_MUTATION = `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

const UPDATE_CHECKOUT_MUTATION = `
  mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        id
        webUrl
      }
      userErrors {
        message
        field
      }
    }
  }
`;

export const ShopifyService = {
    // Fetch all products
    async getProducts(first = 20) {
        try {
            const response = await shopifyClient.request(PRODUCTS_QUERY, { first });
            return response.products.edges.map(({ node }) => node);
        } catch (error) {
            console.error('Error fetching products:', error);
            throw error;
        }
    },

    // Fetch a single product by handle
    async getProductByHandle(handle) {
        try {
            const response = await shopifyClient.request(PRODUCT_BY_HANDLE_QUERY, { handle });
            return response.productByHandle;
        } catch (error) {
            console.error('Error fetching product:', error);
            throw error;
        }
    },

    searchProducts,

    async createCheckout(cartItems) {
        const lineItems = cartItems.map(item => ({
            variantId: item.variantId,
            quantity: item.quantity
        }));

        const variables = {
            input: {
                lineItems,
            }
        };

        try {
            const response = await shopifyClient.request(CREATE_CHECKOUT_MUTATION, variables);

            if (response.checkoutCreate.checkoutUserErrors.length > 0) {
                throw new Error(response.checkoutCreate.checkoutUserErrors[0].message);
            }

            return response.checkoutCreate.checkout.webUrl;
        } catch (error) {
            console.error('Error creating checkout:', error);
            throw error;
        }
    },

    async updateCheckout(checkoutId, cartItems) {
        const lineItems = cartItems.map(item => ({
            variantId: item.variant.id,
            quantity: item.quantity
        }));

        const variables = {
            checkoutId,
            lineItems
        };

        try {
            const response = await shopifyClient.request(UPDATE_CHECKOUT_MUTATION, variables);

            if (response.checkoutLineItemsReplace.userErrors.length > 0) {
                throw new Error(response.checkoutLineItemsReplace.userErrors[0].message);
            }

            return response.checkoutLineItemsReplace.checkout.webUrl;
        } catch (error) {
            console.error('Error updating checkout:', error);
            throw error;
        }
    }
}; 