import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileMenu = ({ isOpen, onClose, setIsSearchOpen }) => {
    const navigate = useNavigate();
    const mainOptions = [
        ['SHOP', 'EXPLORE', 'BRAND'],
        ['SEARCH', 'ACCOUNT']
    ];

    const bottomOptions = [
        ['PRIVACY POLICY', 'SHIPPING AND RETURNS'],
        ['COUNTRY: USA', 'CURRENCY: USD'],
        ['INSTAGRAM', 'TIKTOK', 'NEWSLETTER']
    ];

    const handleOptionClick = (option) => {
        switch (option) {
            case 'SHOP':
                navigate('/shop');
                break;
            case 'EXPLORE':
                navigate('/explore');
                break;
            case 'BRAND':
                navigate('/brand');
                break;
            case 'SEARCH':
                setIsSearchOpen(true);
                break;
            case 'ACCOUNT':
                // Handle account click when implemented
                break;
            case 'PRIVACY POLICY':
                navigate('/privacy-policy');
                break;
            case 'SHIPPING AND RETURNS':
                navigate('/shipping-returns');
                break;
            // Add other cases as needed
        }
        onClose();
    };

    return (
        <div
            className={`fixed top-0 left-0 h-full w-[70%] bg-[#4E8926] transform transition-transform duration-300 ease-in-out z-50 
                ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
            style={{ maxWidth: '70%' }}
        >
            <div className="h-full flex flex-col justify-between px-[16px] py-[20px]">
                {/* Top Section */}
                <div>
                    <button
                        onClick={onClose}
                        className="text-black text-xs tracking-wider hover:opacity-50 transition-opacity duration-200"
                    >
                        CLOSE
                    </button>

                    {/* Main Navigation Options */}
                    <div className="mt-[60px] space-y-[50px]">
                        {/* First Group */}
                        <div className="">
                            {mainOptions[0].map((option) => (
                                <div key={option}>
                                    <button
                                        onClick={() => handleOptionClick(option)}
                                        className="text-[24px] text-black hover:opacity-50 transition-opacity duration-200"
                                    >
                                        {option}
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* Second Group */}
                        <div className="">
                            {mainOptions[1].map((option) => (
                                <div key={option}>
                                    <button
                                        onClick={() => handleOptionClick(option)}
                                        className="text-[24px] text-black hover:opacity-50 transition-opacity duration-200"
                                    >
                                        {option}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="space-y-[30px]">
                    {bottomOptions.map((group, groupIndex) => (
                        <div key={groupIndex} className="space-y-[10px]">
                            {group.map((option) => (
                                <div key={option}>
                                    <button
                                        onClick={() => handleOptionClick(option)}
                                        className="text-xs tracking-wider text-black hover:opacity-50 transition-opacity duration-200"
                                    >
                                        {option}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MobileMenu; 