import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    return (
        <div className="relative">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative bg-[#4E8926] min-h-screen">
                    <div className="px-4 md:px-[30px] pt-[120px] md:absolute md:bottom-0 md:pt-0 md:mb-[30px] max-w-[450px]">
                        <div className="space-y-[30px]">
                            <div>
                                <h2 className="mb-4 text-[12px] font-normal tracking-wider text-black">404 TIMES OOOPS!</h2>
                                <p className="text-[12px] leading-[15px] font-normal tracking-wider text-black">
                                    You were lookin' for love on all the wrong places. It's not you, it's us. That link is broken. And now our hearts are, too. Let's find your match with one of these most-loved styles.
                                </p>
                            </div>

                            <div>
                                <Link
                                    to="/shop"
                                    className="text-[12px] tracking-wider text-black hover:opacity-50 transition-opacity duration-200"
                                >
                                    BACK TO SHOP
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound; 