import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopifyService } from '../services/shopifyService';

const Search = ({ isOpen, onClose }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    // Add debounce function
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // Debounced search function
    const debouncedSearch = debounce(async (query) => {
        if (!query.trim()) {
            setSearchResults([]);
            return;
        }

        setIsLoading(true);
        try {
            const results = await ShopifyService.searchProducts(query);
            setSearchResults(results);
        } catch (error) {
            console.error('Search error:', error);
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    }, 300);

    const handleSearch = (query) => {
        setSearchQuery(query);
        debouncedSearch(query);
    };

    const handleProductClick = (handle) => {
        navigate(`/product/${handle}`);
        onClose();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target) && isOpen) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    return (
        <div
            ref={searchRef}
            className={`fixed left-0 w-full bg-[#4E8926] transition-all duration-300 ease-in-out z-40
                ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}
            style={{ height: '50vh' }}
        >
            {/* Search Button */}
            <button
                onClick={() => debouncedSearch(searchQuery)}
                className="w-full px-8 py-3 bg-black text-white text-xs tracking-wider 
                        hover:bg-[#323232] transition-opacity duration-200 mb-[30px]"
            >
                {isLoading ? 'SEARCHING...' : 'SEARCH'}
            </button>

            {/* Hoverable Image Display */}
            {hoveredItem && (
                <div className="hidden md:block absolute bottom-[30px] right-[30px] z-50">
                    <img
                        src={hoveredItem.image.url}
                        alt={hoveredItem.title}
                        className="w-[80px] h-[104px] object-cover"
                    />
                </div>
            )}

            <div className="w-full md:px-[30px] px-4">
                {/* Search Input */}
                <div className="w-full">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="TYPE IN YOUR SEARCH AND PRESS SEARCH"
                        className="w-full bg-transparent border-b border-black pb-[2px] text-xs tracking-wider focus:outline-none placeholder-black"
                    />
                </div>

                {/* Search Results */}
                <div className="w-full mt-[30px]">
                    {isLoading ? (
                        <div className="text-xs tracking-wider">Searching...</div>
                    ) : searchResults.length > 0 ? (
                        <div>
                            {/* Top underline */}
                            <div className="w-full border-t border-black mb-[10px]"></div>

                            {/* Results with bottom borders */}
                            <div className="flex flex-col">
                                {searchResults.map((item) => (
                                    <div
                                        key={item.id}
                                        className="w-full text-xs tracking-wider cursor-pointer hover:opacity-50 transition-opacity duration-200 pb-[5px] mb-[5px] border-b border-black"
                                        onClick={() => handleProductClick(item.handle)}
                                        onMouseEnter={() => setHoveredItem(item)}
                                        onMouseLeave={() => setHoveredItem(null)}
                                    >
                                        {item.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : searchQuery.trim() ? (
                        <div className="text-xs tracking-wider">No results found</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Search; 