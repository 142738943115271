import React from 'react';
import PathNavigation from './PathNavigation';
import Footer from './Footer';

const Stockists = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    // Example stockist data, sorted alphabetically by country
    const stockists = [
        { country: "Australia", city: "Melbourne", storeName: "Fashion Hub" },
        { country: "France", city: "Paris", storeName: "Le Style" },
        { country: "Japan", city: "Tokyo", storeName: "Modern Arts" },
        { country: "United Kingdom", city: "London", storeName: "The Corner" },
        { country: "USA", city: "New York", storeName: "Downtown Gallery" }
    ].sort((a, b) => a.country.localeCompare(b.country));

    return (
        <div className="relative min-h-screen">
            <div className={`transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-[70%]' : 'translate-x-0'}`}>
                <div className="relative min-h-screen">
                    <div className="px-4 md:px-[30px] h-screen pt-[120px]">
                        {/* Stockists Header */}
                        <div className="max-w-[450px]">
                            <h1 className="text-xs tracking-wider mb-[10px]">STOCKISTS</h1>
                        </div>

                        {/* Stockists List */}
                        <div className="max-w-[450px]">
                            {stockists.map((stockist, index) => (
                                <div
                                    key={index}
                                    className="text-xs tracking-wider"
                                >
                                    {`${stockist.country}, ${stockist.city}, ${stockist.storeName}`}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Path Navigation */}
                    <PathNavigation />

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Stockists; 