import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const addToCart = (product, variant, quantity = 1) => {
        setCart(prevCart => {
            // Check if item already exists in cart
            const existingItemIndex = prevCart.findIndex(
                item => item.variantId === variant.id
            );

            if (existingItemIndex >= 0) {
                // Update quantity if item exists
                const newCart = [...prevCart];
                newCart[existingItemIndex].quantity += quantity;
                return newCart;
            }

            // Add new item if it doesn't exist
            return [...prevCart, {
                id: product.id,
                title: product.title,
                variantId: variant.id,
                variantTitle: variant.title,
                price: variant.price,
                image: product.images.edges[0]?.node,
                quantity
            }];
        });
    };

    const removeFromCart = (variantId) => {
        setCart(prevCart => prevCart.filter(item => item.variantId !== variantId));
    };

    const updateQuantity = (variantId, quantity) => {
        setCart(prevCart => prevCart.map(item =>
            item.variantId === variantId ? { ...item, quantity } : item
        ));
    };

    const getCartTotal = () => {
        return cart.reduce((total, item) => total + (parseFloat(item.price.amount) * item.quantity), 0);
    };

    const getCartCount = () => {
        return cart.reduce((total, item) => total + item.quantity, 0);
    };

    return (
        <CartContext.Provider value={{
            cart,
            addToCart,
            removeFromCart,
            updateQuantity,
            getCartTotal,
            getCartCount
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
}; 