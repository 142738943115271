import React from 'react';
import { useNavigate } from 'react-router-dom';

const RelatedItems = ({ products, currentProductId }) => {
    const navigate = useNavigate();

    const handleProductClick = (handle) => {
        navigate(`/product/${handle}`);
    };

    // Filter out the current product
    const filteredProducts = products.filter(product => product.id !== currentProductId);

    return (
        <div className="w-full">
            {/* Spacer section */}
            <div className="md:h-[180px] h-[60px]"></div>

            {/* Related Items Title */}
            <div className="px-[16px] md:px-[30px] mb-[30px]">
                <h2 className="text-xs tracking-wider">RELATED ITEMS</h2>
            </div>

            {/* Products Grid */}
            <div className="flex">
                {filteredProducts.map((product) => {
                    const image = product.images.edges[0]?.node;
                    const price = product.priceRange.minVariantPrice;

                    return (
                        <div
                            key={product.id}
                            onClick={() => handleProductClick(product.handle)}
                            className="group cursor-pointer"
                        >
                            {/* Image Container */}
                            <div className="w-[140px] h-[180px] overflow-hidden">
                                <img
                                    src={image?.url}
                                    alt={image?.altText || product.title}
                                    className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-90"
                                />
                            </div>

                            {/* Product Info Below Image */}
                            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-[10px] mb-[10px] ml-[10px]">
                                <div className="text-xs tracking-wider">
                                    {product.title.toUpperCase()}
                                </div>
                                <div className="text-xs tracking-wider">
                                    {price.amount} {price.currencyCode}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RelatedItems; 